export default [
    {
        "fa": "fab fa-instagram",
        "value": "Instagram",
        "linkTo": "https://www.instagram.com/40not50/",
    },
    {
        "fa": "fab fa-facebook",
        "value": "Facebook",
        "linkTo": "https://www.facebook.com/40not50"
    },
    {
        "fa": "fa-brands fa-threads",
        "value": "Threads",
        "linkTo": "https://www.threads.net/@40not50",
    },
    {
        "fa": "fa-brands fa-x-twitter",
        "value": "Twitter",
        "linkTo": "https://twitter.com/40not50"
    },
    {
        "fa": "fas fa-comments",
        "value": "Sign the Petition",
        "linkTo": "https://www.change.org/p/all-women-over-the-age-of-30-start-mammograms-at-age-40-not-50"
    },
    {
        "fa": "fas fa-globe",
        "value": "Our Website",
        "linkTo": "http://www.40not50.org/"
    }
]