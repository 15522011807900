import React from 'react';
import './App.css';
import links from "./links";
import Button from './button';
import {Helmet} from 'react-helmet';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

export default class App extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      darkMode: false,
      links: links
    }
    this.changeCSS = this.changeCSS.bind(this)
  }
  componentDidMount(){
    let d = new Date();
    let h = d.getHours();
    if(h>18 ||  h <8){
      this.changeCSS();
    }
  }
  changeCSS(){
    this.setState({ darkMode: !this.state.darkMode })
  }
  render(){
    let routes = this.state.links.map((link, index)=>{
      return (
        <Route key={index} path={link.path}>
          <Page name={link.name} featuredImage={link.logo} featuredHandle={link.featuredHandle} darkMode={this.state.darkMode} changeCSS={this.changeCSS} links={link.manifest} title={link.title} description={link.description} story={link.story} storyLinkTo={link.storyLinkTo} />
        </Route>
        )
    })

    return (
      <Router>
         {/* Refactor */}
          <Switch>
            {routes}
          </Switch>
      </Router>
    );
  }
}


function Page(props){
  let buttons = props.links.map((link,index)=>{
    return (
      <Button fa={link.fa} value={link.value} linkTo={link.linkTo} key={index} />
    )
  })
  let featured = props.links.slice(0,1)[0]

  //if we have a featured banner in tree
  let story = "";
  if(props.story && props.storyLinkTo){
    console.log('hey')
    story = (<div className="featured-story"><p>{props.story}</p></div>)
  }
  const d = new Date();
  let year = d.getFullYear();
  return (
    <div className={`App ${props.name} ${props.darkMode ? 'dark':'light'}`}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{props.title}</title>
          <meta name="description" content={props.description} />
          <script type="text/javascript">
            {'!function(){var b=function(){window.__AudioEyeSiteHash = "019a8d76edb9c40464be20df5ac81be2"; var a=document.createElement("script");a.src="https://wsmcdn.audioeye.com/aem.js";a.type="text/javascript";a.setAttribute("async","");document.getElementsByTagName("body")[0].appendChild(a)};"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",b):window.attachEvent&&window.attachEvent("onload",b):b()}();'}
          </script>
      </Helmet>
      <span className='radnetLogo'>
        <a href={featured.linkTo} target='_blank'> 
          <img src={props.featuredImage} />
          </a>
      </span>
      <p className='at-me'>
        <a href={featured.linkTo} target="_blank">
        @{props.featuredHandle}</a>
      </p>
      {story}
        <div>
        {props.name === 'radnetimaging' ? ( 
          //radnetimaging URL
          <div className="content radnetimaging">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3>Imaging Centers</h3>
                  <a className="btn" href="https://www.arizonadiagnosticradiology.com/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; Arizona Diagnostic Radiology</a>
                  <a className="btn" href="https://breastlink.com/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; Breastlink</a>
                  <a className="btn" href="https://www.radnet.com/kern-radiology/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet Bakersfield (Kern Radiology)</a>
                  <a className="btn" href="https://www.radnet.com/central-california/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet Central California</a>
                  <a className="btn" href="https://www.radnet.com/coachella-valley/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet Coachella Valley</a>
                  <a className="btn" href="https://www.radnet.com/high-desert/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet High Desert</a>
                  <a className="btn" href="https://www.radnet.com/inland-empire/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet Inland Empire</a>
                  <a className="btn" href="https://www.radnet.com/lancaster" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet Lancaster</a>
                  <a className="btn" href="https://www.radnet.com/long-beach/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet Long Beach</a>
                  <a className="btn" href="https://www.radnet.com/los-angeles/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet Los Angeles</a>
                  <a className="btn" href="https://www.radnet.com/northern-california/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet Northern California</a>
                  <a className="btn" href="https://www.radnet.com/orange-county/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet Orange County</a>
                  <a className="btn" href="https://www.radnet.com/san-fernando-valley/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet San Fernando Valley</a>
                  <a className="btn" href="https://www.radnet.com/san-gabriel-valley/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet San Gabriel Valley</a>
                  <a className="btn" href="https://www.radnet.com/south-bay/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet South Bay</a>
                  <a className="btn" href="https://www.radnet.com/temecula-valley/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet Temecula Valley</a>
                  <a className="btn" href="https://www.radnet.com/rolling-oaks-radiology/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; RadNet Ventura (Rolling Oaks Radiology)</a>
                  {/*<a className="btn" href="https://www.valleyradiologyimaging.com/" target="_blank"><i className="fas fa-map-marker-alt" aria-hidden="true"></i>&nbsp; Valley Radiology</a>*/}
                </div>
                <div className="col">
                  <h3>Services, Resources &amp; Media</h3>
                  {buttons}
                </div>
                <div className="col">
                  <h3>Social Media</h3>
                  <div>
                    <a className="btn" href="https://www.instagram.com/radnetimaging" target="_blank"><i className="fab fa-instagram" aria-hidden="true"></i>&nbsp; Instagram</a>
                    <a className="btn" href="https://www.facebook.com/RadNetImaging" target="_blank"><i className="fab fa-facebook" aria-hidden="true"></i>&nbsp; Facebook</a>
                    <a className="btn" href="https://www.threads.net/@radnetimaging" target="_blank"><i className="fa-brands fa-threads" aria-hidden="true"></i>&nbsp; Threads</a>
                    <a className="btn" href="https://twitter.com/radnetimaging" target="_blank"><i className="fa-brands fa-x-twitter" aria-hidden="true"></i>&nbsp; Twitter</a>
                    <a className="btn" href="https://www.pinterest.com/RadNetImaging/" target="_blank"><i className="fab fa-pinterest" aria-hidden="true"></i>&nbsp; Pinterest</a>
                    <a className="btn" href="https://www.youtube.com/RadNetVideos/" target="_blank"><i className="fab fa-youtube" aria-hidden="true"></i>&nbsp; YouTube</a>
                    <a className="btn" href="https://www.tiktok.com/@radnetimaging" target="_blank"><i className="fab fa-tiktok" aria-hidden="true"></i>&nbsp; TikTok</a>
                    <a className="btn" href="https://www.linkedin.com/company/14101" target="_blank"><i className="fab fa-linkedin" aria-hidden="true"></i>&nbsp; Linkedin</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          //Normal URL
          <div className="content">
            {buttons}
          </div>
        )}
      </div>
      <span className='toggleTextMode'>{props.darkMode ? 'Light': 'Dark'} Mode: </span><span className='toggleColorContainer' onClick={props.changeCSS}><a className={`toggleColorMode ${props.darkMode ? 'toggled' : ''}`} ></a></span>
          <p className="footer">
            Copyright &copy; 1984-{year} | <a href='https://www.radnet.com/' target="_blank">RadNet, Inc.</a>
          </p>
      </div>
  )
}
