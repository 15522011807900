export default [
    {
        "fa": "fab fa-facebook",
        "value": "Facebook",
        "linkTo": "https://www.facebook.com/RadNetInc/"
    },
    {
        "fa": "fa-brands fa-x-twitter",
        "value": "Twitter",
        "linkTo": "https://twitter.com/RadNet_Inc"
    },
    {
        "fa": "fab fa-linkedin",
        "value": "Linkedin",
        "linkTo": "https://www.linkedin.com/company/14101/admin/"
    }
]