export default [
    {
        "fa": "fab fa-facebook",
        "value": "Facebook",
        "linkTo": "https://www.facebook.com/breastlinknewyork/"
    },
    {
        "fa": "fab fa-instagram",
        "value": "Instagram",
        "linkTo": "https://www.instagram.com/breastlinkny/"
    },
    {
        "fa": "fa-brands fa-x-twitter",
        "value": "Twitter",
        "linkTo": "https://twitter.com/breastlinkny"
    },
    {
        "fa": "fab fa-pinterest",
        "value": "Pinterest",
        "linkTo": "https://www.pinterest.com/breastlink/"
    },
    {
        "fa": "fas fa-pencil-alt",
        "value": "Blog",
        "linkTo": "https://newyork.breastlink.com/blog/"
    },
    {
        "fa": "fas fa-globe",
        "value": "Our Website",
        "linkTo": "http://newyork.breastlink.com/"
    },
    {
        "fa": "fas fa-user-nurse",
        "value": "Physician Assistant Opportunity",
        "linkTo": "https://www.linkedin.com/jobs/view/1913671420/?trk=cap_redirect"
    }
]