import React from 'react'
import './button.css'

function Button(props){
    const {linkTo, target, value, fa} = props
    const tar = target || '_blank'

    return (
        <div>
            <a className='btn' href={linkTo} target={tar}>
            <i className={fa} aria-hidden="true"></i>&nbsp; {value}</a>
        </div>

    );
}
    
export default Button;     
