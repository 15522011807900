export default [
    {
        "fa": "fab fa-instagram",
        "value": "Instagram",
        "linkTo": "https://www.instagram.com/imagingspecialistsofglendale/"
    },
    {
        "fa": "fab fa-facebook",
        "value": "Facebook",
        "linkTo": "https://www.facebook.com/ISGlendale/"
    },
    {
        "fa": "fa-brands fa-threads",
        "value": "Threads",
        "linkTo": "https://www.threads.net/@ImagingSpecialistsofGlendale"
    },
    {
        "fa": "fa-brands fa-x-twitter",
        "value": "Twitter",
        "linkTo": "https://twitter.com/isglendale"
    },
    {
        "fa": "fas fa-microphone",
        "value": "Your Body Podcast",
        "linkTo": "https://www.yourbodyshow.com/"
    },
    {
        "fa": "fas fa-globe",
        "value": "Our Website",
        "linkTo": "https://www.imagingspecialists.com/"
    },
    {
        "fa": "fas fa-globe",
        "value": "Enhanced Breast Cancer Detection (EBCD)",
        "linkTo": "https://myebcdmammo.com"
    },
    {
        "fa": "fas fa-pencil-alt",
        "value": "Blog",
        "linkTo": "https://www.imagingspecialists.com/blog"
    },
    {
        "fa": "fa fa-globe",
        "value": "RadNet Prostate",
        "linkTo": "https://prostate.radnet.com"
    },
    {
        "fa": "fa fa-globe",
        "value": "Choice RadNet",
        "linkTo": "https://choice.radnet.com/"
    },
    {
        "fa": "fas fa-globe",
        "value": "XRayHours.com",
        "linkTo": "https://xrayhours.com/sgv"
    },
    {
        "fa": "fas fa-lungs",
        "value": "Lung Cancer Screening",
        "linkTo": "https://radnetimaging.com/low-dose-lung-ct-scan/"
    }
]