export default [
    {
        "fa": "fab fa-facebook",
        "value": "Facebook",
        "linkTo": "https://www.facebook.com/RollingOaksRadiologyThousandOaks/"
    },
    {
        "fa": "fab fa-instagram",
        "value": "Instagram",
        "linkTo": "https://www.instagram.com/rollingoaksradiology/"
    },
    {
        "fa": "fa-brands fa-threads",
        "value": "Threads",
        "linkTo": "https://www.threads.net/@rollingoaksradiology"
    },
    {
        "fa": "fab fa-linkedin",
        "value": "Linkedin",
        "linkTo": "https://www.linkedin.com/company/15627407"
    },
    {
        "fa": "fas fa-globe",
        "value": "Our Website",
        "linkTo": "https://www.radnet.com/ror",
    },
    {
        "fa": "fas fa-globe",
        "value": "Enhanced Breast Cancer Detection (EBCD)",
        "linkTo": "https://www.radnet.com/rolling-oaks-radiology/ebcd"
    },
    {
        "fa": "fas fa-pencil-alt",
        "value": "Blog",
        "linkTo": "https://www.radnet.com/rolling-oaks-radiology/blog"
    },
    {
        "fa": "fas fa-globe",
        "value": "RadNet Prostate",
        "linkTo": "https://prostate.radnet.com"
    },
    {
        "fa": "fa fa-globe",
        "value": "Choice RadNet",
        "linkTo": "https://choice.radnet.com/"
    },
    {
        "fa": "fas fa-globe",
        "value": "XRayHours.com",
        "linkTo": "https://xrayhours.com/ror"
    },
    {
        "fa": "fas fa-lungs",
        "value": "Lung Cancer Screening",
        "linkTo": "https://radnetimaging.com/low-dose-lung-ct-scan/"
    }
]