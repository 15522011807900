export default [
    {
        "fa": "fab fa-facebook",
        "value": "Facebook",
        "linkTo": "  https://www.facebook.com/HoustonMedImaging"
    },
    {
        "fa": "fab fa-instagram",
        "value": "Instagram",
        "linkTo": "https://www.instagram.com/houstonmedimaging"
    },
    {
        "fa": "fa-brands fa-threads",
        "value": "Threads",
        "linkTo": "https://www.threads.net/@houstonmedimaging"
    },
    {
        "fa": "fab fa-linkedin",
        "value": "Linkedin",
        "linkTo": " https://www.linkedin.com/company/houstonmedimaging"
    },
    {
        "fa": "fa-brands fa-x-twitter",
        "value": "Twitter",
        "linkTo": "https://x.com/houstonmedimage"
    },
    {
        "fa": "fab fa-tiktok",
        "value": "TikTok",
        "linkTo": "https://www.tiktok.com/@houstonmedimaging"
    },
    {
        "fa": "fab fa-youtube",
        "value": "YouTube",
        "linkTo": "https://www.youtube.com/@houstonmedimaging"
    },
    {
        "fa": "fas fa-microphone",
        "value": "Your Body Podcast",
        "linkTo": "https://www.yourbodyshow.com/"
    },
    {
        "fa": "fas fa-globe",
        "value": "Our Website",
        "linkTo": "https://hmixray.com/"
    }
]