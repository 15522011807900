export default [
    {
        "fa": "fab fa-instagram",
        "value": "Instagram",
        "linkTo": "https://instagram.com/breastlink"
    },
    {
        "fa": "fab fa-facebook",
        "value": "Facebook",
        "linkTo": "https://www.facebook.com/breastlink"
    },
    {
        "fa": "fa-brands fa-threads",
        "value": "Threads",
        "linkTo": "https://www.threads.net/@breastlink"
    },
    {
        "fa": "fa-brands fa-x-twitter",
        "value": "Twitter",
        "linkTo": "https://www.twitter.com/breastlink"
    },
    {
        "fa": "fab fa-pinterest",
        "value": "Pinterest",
        "linkTo": "https://www.pinterest.com/breastlink/"
    },
    {
        "fa": "fab fa-youtube",
        "value": "YouTube",
        "linkTo": "https://www.youtube.com/user/breastlink1"
    },
    {
        "fa": "fab fa-linkedin",
        "value": "Linkedin",
        "linkTo": "https://www.linkedin.com/company/2623772/"
    },
    {
        "fa": "fab fa-tiktok",
        "value": "TikTok",
        "linkTo": "https://www.tiktok.com/@breastlink"
    },
    {
        "fa": "fas fa-globe",
        "value": "MammogramNow",
        "linkTo": "https://breastlink.com/services/mammogramnow"
    },
    {
        "fa": "fas fa-globe",
        "value": "Our Website",
        "linkTo": "https://breastlink.com/"
    },
    {
        "fa": "fas fa-globe",
        "value": "Enhanced Breast Cancer Detection (EBCD)",
        "linkTo": "https://breastlink.com/services/enhanced-breast-cancer-detection"
    },
    {
        "fa": "fas fa-pencil-alt",
        "value": "Blog",
        "linkTo": "https://breastlink.com/blog/"
    },
    {
        "fa": "fas fa-female",
        "value": "#MaddyTheMammoCoach",
        "linkTo": "https://breastlink.com/maddy/"
    },
    {
        "fa": "fas fa-microphone",
        "value": "Your Body Podcast",
        "linkTo": "https://www.yourbodyshow.com/"
    },
    {
        "fa": "fas fa-ribbon",
        "value": "High-Risk Program",
        "linkTo": "https://breastlink.com/services/risk-assessment"
    },
    {
        "fa": "fa-solid fa-book",
        "value": "Purchase ‘The Breast Cancer Survival Manual’",
        "linkTo": "https://www.amazon.com/Breast-Cancer-Survival-Manual-Seventh/dp/1250894999"
    },
]