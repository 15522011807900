export default [
    {
        "fa": "fab fa-facebook",
        "value": "Facebook",
        "linkTo": "https://www.facebook.com/scriptsender"
    },
    {
        "fa": "fa-brands fa-x-twitter",
        "value": "Twitter",
        "linkTo": "https://twitter.com/scriptsender"
    },
    {
        "fa": "fab fa-linkedin",
        "value": "Linkedin",
        "linkTo": "https://www.linkedin.com/company/6427666/admin/"
    },
    {
        "fa": "fab fa-youtube",
        "value": "YouTube",
        "linkTo": "https://www.youtube.com/RadNetVideos/"
    },
    {
        "fa": "fab fa-vimeo",
        "value": "Vimeo",
        "linkTo": "https://vimeo.com/scriptsender"
    },
    {
        "fa": "fas fa-globe",
        "value": "Our Website",
        "linkTo": "https://www.scriptsender.com"
    },
    {
        "fa": "fas fa-pencil-alt",
        "value": "Blog",
        "linkTo": "https://scriptsender.com/news/"
    },
]