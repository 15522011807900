import radNetLogo from './radnet-logo.jpeg';
import fnfiftyLogo from './40not50.jpg';
import crcLogo from './crc.jpg';
import breastlinkLogo from './breastlink.jpg';
import eradLogo from './erad.png';
import radsiteLogo from './radsite.jpg';
import scriptsenderLogo from './scriptsender.jpg';
import blnyLogo from './blny.jpg';
import kernLogo from './kern.jpg';
import isgLogo from './isg.jpg';
import rorLogo from './ror.jpg';
import careersLogo from "./radnetcareers.png"
import waveLogo from "./waveimaging.jpg"
import azLogo from "./arizonadiagnosticradiology.jpg"
import hmiLogo from "./houston-medical-imaging-logo.png"

import homeManifest from "./home-manifest";
import radnetManifest from "./radnet-manifest";
import breastLinkManifest from "./breastlink-manifest";
import crcManifest from "./crc-manifest";
import fnfiftyManifest from "./fnfifty-manifest";
import radSiteManifest from "./radsite-manifest";
import scriptSenderManifest from "./scriptsender-manifest";
import eRadManifest from "./erad-manifest";
import blnyManifest from "./blny-manifest";
import isgManifest from "./isg-manifest";
import rorManifest from "./ror-manifest";
import kernManifest from "./kern-manifest";
import careersManifest from "./careers-manifest"
import waveManifest from "./wave-manifest";
import azManifest from "./az-manifest";
import hmiManifest from "./hmi-manifest";
import radnetImagingManifest from "./radnetimaging-manifest";

export default [
    {
      name: "radnetimaging",
      path: "/radnetimaging",
      manifest: radnetImagingManifest,
      logo: radNetLogo,
      featuredHandle: "RadNetImaging",
      title: "@radnetimaging | rad.link",
      description: "Get connected with all RadNet Imaging social media profiles.",
      story:"RadNet Imaging stands as a beacon of outpatient imaging, with a network of over 350 centers nationwide. Recognized as the largest provider of outpatient imaging in the US, RadNet consistently sets the benchmark for radiology best practices. Our specialty centers of excellence, strategic alliances, cutting-edge technology, and collaborative partnerships all underscore our commitment to not only exceptional imaging services but also to delivering the very best in patient care.",
      storyLinkTo:"https://www.radnet.com/imaging-centers/california"
    },
    {
      name: "breastlink",
      path: "/breastlink",
      manifest: breastLinkManifest,
      logo: breastlinkLogo,
      featuredHandle: "breastlink",
      title: "@breastlink | rad.link",
      description: "Get connected with all Breastlink social media profiles."
    },
    {
      name: "crc",
      path: "/crc",
      manifest: crcManifest,
      logo: crcLogo,
      featuredHandle: "CRCollaboration",
      title: "@CRCollaboration | rad.link",
      description: "Get connected with all CRC social media profiles."
    },
    {
      name: "erad",
      path: "/erad",
      manifest: eRadManifest,
      logo: eradLogo,
      featuredHandle: "eRADsolutions",
      title: "@eRADsolutions | rad.link",
      description: "Get connected with all eRAD social media profiles."
    },
    {
      name: "scriptsender",
      path: "/scriptsender",
      manifest: scriptSenderManifest,
      logo: scriptsenderLogo,
      featuredHandle: "ScriptSender",
      title: "@ScriptSender | rad.link",
      description: "Get connected with all ScriptSender social media profiles."
    },
    {
      name: "radsite",
      path: "/radsite",
      manifest: radSiteManifest,
      logo: radsiteLogo,
      featuredHandle: "RadSiteNews",
      title: "@radsite | rad.link",
      description: "Get connected with all RadSite social media profiles."
    },
    {
      name: "fnfifty",
      path: "/40not50",
      manifest: fnfiftyManifest,
      logo: fnfiftyLogo,
      featuredHandle: "40not50",
      title: "@40not50 | rad.link",
      description: "Get connected with all 40not50 social media profiles."
    },
    {
      name: "radnet",
      path: "/radnet",
      manifest: radnetManifest,
      logo: radNetLogo,
      featuredHandle: "RadNet_Inc",
      title: "@RadNet_Inc | rad.link",
      description: "Get connected with all RadNet Corporate social media profiles."
    },
    {
      name: "blny",
      path: "/blny",
      manifest: blnyManifest,
      logo: blnyLogo,
      featuredHandle: "Breastlink NY",
      title: "@breastlinkNY | rad.link",
      description: "Get connected with all BreastlinkNY social media profiles."
    },
    {
      name: "isg",
      path: "/isg",
      manifest: isgManifest,
      logo: isgLogo,
      featuredHandle: "isglendale",
      title: "@isglendale | rad.link",
      description: "Get connected with all Imaging Specialists of Glendale social media profiles."
    },
    {
      name: "kern",
      path: "/kern",
      manifest: kernManifest,
      logo: kernLogo,
      featuredHandle: "kernradiology",
      title: "@kernradiology | rad.link",
      description: "Get connected with all RadNet Kern social media profiles."
    },
    {
      name: "ror",
      path: "/ror",
      manifest: rorManifest,
      logo: rorLogo,
      featuredHandle: "rollingoaksradiology",
      title: "@rollingoaksradiology | rad.link",
      description: "Get connected with all Rolling Oaks Radiology social media profiles."
    },
    {
      name: "waveimaging",
      path: "/waveimaging",
      manifest: waveManifest,
      logo: waveLogo,
      featuredHandle: "WaveImaging",
      title: "@waveimaging | rad.link",
      description: "Get connected with all WaveImaging social media profiles."
    },
    {
      name: "azdrg",
      path: "/azdrg",
      manifest: azManifest,
      logo: azLogo,
      featuredHandle: "ArizonaRadiology",
      title: "@azdrg | rad.link",
      description: "Get connected with all Arizona Diagnostic Radiology social media profiles."
    },
    {
      name: "houstonmedimaging",
      path: "/houstonmedimaging",
      manifest: hmiManifest,
      logo: hmiLogo,
      featuredHandle: "HoustonMedImaging",
      title: "@houstonmedimaging | rad.link",
      description: "Get connected with all Houston Medical Imaging social media profiles."
    },
    {
      name: "",
      path: "/",
      manifest: homeManifest,
      logo: radNetLogo,
      featuredHandle: "RadNetImaging",
      title: "@radnetimaging | rad.link",
      description: "Get connected with all RadNet Imaging social media profiles."
    }
  ]