export default [
    {
        "fa": "fa-brands fa-x-twitter",
        "value": "Twitter",
        "linkTo": "https://twitter.com/RadSiteNews"
    },
    {
        "fa": "fab fa-linkedin",
        "value": "Linkedin",
        "linkTo": "https://www.linkedin.com/company/1961606/admin/"
    },
    {
        "fa": "fas fa-globe",
        "value": "Our Website",
        "linkTo": "https://www.radsitequality.com"
    },
    {
        "fa": "fas fa-pencil-alt",
        "value": "Blog",
        "linkTo": "https://www.radsitequality.com/news"
    },
]