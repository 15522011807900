export default [
    {
        "fa": "fab fa-facebook",
        "value": "Facebook",
        "linkTo": "https://www.facebook.com/CancerResearchCollaboration"
    },
    {
        "fa": "fa-brands fa-x-twitter",
        "value": "Twitter",
        "linkTo": "https://twitter.com/CRCollaboration"
    },
    {
        "fa": "fab fa-linkedin",
        "value": "Linkedin",
        "linkTo": "https://www.linkedin.com/company/6410789"
    },
    {
        "fa": "fas fa-credit-card",
        "value": "Donate",
        "linkTo": "https://www.cancerresearchcollaboration.org/donate"
    },
    {
        "fa": "fas fa-globe",
        "value": "Our Website",
        "linkTo": "https://www.cancerresearchcollaboration.org/news"
    },
    {
        "fa": "fas fa-pencil-alt",
        "value": "Blog",
        "linkTo": "https://breastlink.com/blog/"
    },
   
]